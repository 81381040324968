// VIDEO
@import "core";

@include respond-to(base-inline) {
  .tc_video{position:relative;}
  .tc_video__video{aspect-ratio:16/9;object-fit:contain;background-color:var(--color-neutral-800);
    & > :first-child:not(.tc_video__close){position:relative;display:block;max-width:100%;width:100%;height:100%;aspect-ratio:inherit;object-fit:inherit;background-color:var(--color-neutral-800);
      &::after{content:"";position:absolute;left:0;right:0;bottom:0;top:0;pointer-events:none;}
      &::after,.vjs-loading-spinner{background-image:url("inline!../../../../icons/spinner.svg");background-repeat:no-repeat;background-position:50%;background-size:var(--spacing-32-step) auto;filter:invert(1) brightness(2);}
    }
    .video-js{z-index:0;
      &:not(:empty):after{background-image:none;}
    }
    .vjs-loading-spinner{border:0;border-radius:0;
      &::before,&::after{display:none}
    }
  }
  .tc_video__video--preview{display:block;position:relative;background-image:unset;background-size:cover;} // preview is used in webviews
  .tc_video__close{display:none;}
  .tc_button.tc_video__close__button{color:var(--color-white);margin:var(--spacing-4);color-scheme:light;}

  .tc_video__videoloop{cursor:default;
    &::-webkit-media-controls{display:none!important;}
  }
  .tc_video__videoloop:not([src]),.tc_video__videoloop[src=""],.tc_video__videoloop--show-poster{background:var(--poster) no-repeat 50% 50%!important;background-size:cover!important;}

  @media (scripting:none) {
    .tc_video__videoloop{background:var(--poster) no-repeat 50% 50%!important;background-size:cover!important;}
  }
}

@include respond-to(base) {
  .tc_video{
    video{background-color:inherit!important;}
    .bc-error_overlay{background-color:inherit!important;}
    .ima-ad-container{z-index:10!important;text-align:inherit;}
    .tc_video__video--preview::before{content:'';position:absolute;top:0;left:0;right:0;bottom:0;z-index:1;background:linear-gradient(180deg,var(--color-midnight-300) 0%,var(--color-midnight-500) 59.9%,var(--color-midnight-600) 82.29%,var(--color-midnight-800) 95.31%,var(--color-midnight-800) 100%)!important;}
    .tc_video__video--preview::after{content:'';display:block;position:absolute;z-index:2;top:0;left:0;bottom:0;right:0;mask:url("../../../../icons/play.svg") no-repeat center;mask-size:var(--spacing-32-jump) auto;background-color:var(--color-white);}
  }
}

@include respond-to(medium) {
  .tc_video__video:not(.tc_video__video--hide) .tv2-videojs-sticky--fixed{position:fixed!important;top:auto!important;left:auto!important;bottom:var(--spacing-56)!important;right:calc(50% - (333 * var(--unit)))!important;z-index:12!important;width:calc((336 * var(--unit)))!important;height:calc(190 * var(--unit))!important;padding:0!important;border:var(--spacing-2) solid var(--color-neutral-900);background:var(--color-neutral-900);
    & + .tc_video__close{display:flex!important;justify-content:flex-end;z-index:12;position:fixed!important;top:auto;left:auto;bottom:calc(244 * var(--unit));right:calc(50% - (333 * var(--unit)));width:calc((336 * var(--unit)))!important;height:var(--spacing-32)!important;background-color:var(--color-neutral-900);background-image:none!important;}
  }
}

@include respond-to(large) {
  .tc_video__video:not(.tc_video__video--hide) .tv2-videojs-sticky--fixed{right:calc(50% - (493 * var(--unit)))!important;
    & + .tc_video__close{right:calc(50% - (493 * var(--unit)));}
  }

  .tv2-player__placeholder--error{
    .error_header{font-size:var(--font-18);}
    & > div{font-size:var(--font-14);}
  }
}

@include respond-to(print) {
  .tc_video{display:none;}

  @supports (aspect-ratio:1/1) {
    .tc_video{display:flex;justify-content:center;align-items:center;max-width:var(--main-medium);border:var(--spacing-2) solid var(--color-black);aspect-ratio:16/9;
      &::before{content:"Video";}
      & > *{display:none;}
    }
  }
}
